import { useCallback, useEffect, useRef, useState } from 'react'

import { useCrash } from '@orus.eu/pharaoh'
import { isFailure, type Result } from '@orus.eu/result'

import { useAsyncCallback } from '@orus.eu/pharaoh'
import { trpc } from '../../../../../client'
import { debounceAsync } from '../../../../../lib/debounce-async'

import type { TemporaryAPIProblem } from '@orus.eu/backend/src/lib/temporary-api-failure'
import type { SubscriptionNonDimensionalData } from '@orus.eu/backend/src/routers/pending-subscriptions'
import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager'
import type { StateChangeNotification } from '@orus.eu/backend/src/views/subscriptions/subscription-state-triggers-applier'
import { ensureError } from '@orus.eu/error'
import deepEqual from 'deep-equal'

export type QuoteEditorStateHook = {
  changes: SubscriptionDimensionnedState
  setChanges(changes: SubscriptionDimensionnedState): void
  before: SubscriptionDimensionnedState | undefined
  after: SubscriptionDimensionnedState | undefined
  nonDimensionalDataAfter: SubscriptionNonDimensionalData | undefined
  stateChangeNotifications: StateChangeNotification[]
  reset: () => void
  commit: () => void
  commiting: boolean
  updating: boolean
}

const computeStateChangesApiDebounced = debounceAsync(trpc.pendingSubscriptions.computeCompleteChanges.mutate, 1000)

/**
 * Specify how the useQuoteEditorState can compute the complete state change from an input update.
 * Also used to retrieve the initial state, because it's like computing the impact of an empty input update
 */
export type ComputeCompleteChangesApi = (params: {
  subscriptionId: string
  changes: SubscriptionDimensionnedState
}) => Promise<
  Result<
    {
      before: SubscriptionDimensionnedState
      after: SubscriptionDimensionnedState
      nonDimensionnalDataAfter: SubscriptionNonDimensionalData
    },
    TemporaryAPIProblem
  >
>

export function useQuoteEditorState({ subscriptionId }: { subscriptionId: string }): QuoteEditorStateHook {
  const crash = useCrash()
  const [commiting, setCommiting] = useState(false)
  const [changes, setChanges] = useState<SubscriptionDimensionnedState>({})
  const [before, setBefore] = useState<SubscriptionDimensionnedState | undefined>()
  const [after, setAfter] = useState<SubscriptionDimensionnedState | undefined>()
  const [nonDimensionalDataAfter, setNonDimensionalDataAfter] = useState<SubscriptionNonDimensionalData | undefined>()
  const [stateChangeNotifications, setInvalidStateMessages] = useState<StateChangeNotification[]>([])
  const [updating, setUpdating] = useState(false)

  const reset = useCallback(() => setChanges({}), [])

  const commit = useAsyncCallback(async () => {
    setCommiting(true)
    const result = await trpc.pendingSubscriptions.commitChanges.mutate({
      subscriptionId,
      changes,
    })
    if (isFailure(result)) {
      switch (result.problem.type) {
        case 'temporary-api-failure':
          crash({ type: 'temporary-problem' })
          return
      }
    }
    const stateWithCommitedChanges = result.output
    setBefore(stateWithCommitedChanges)
    setChanges({})
    setCommiting(false)
  }, [setChanges, changes, crash, subscriptionId])

  const changesRef = useRef(changes)

  const overrideChangesByStateAfter = useCallback(
    (state: SubscriptionDimensionnedState | undefined) => {
      if (!state) return

      const newChanges = Object.fromEntries(
        (Object.keys(changes) as Array<keyof SubscriptionDimensionnedState>).map((dimension) => [
          dimension,
          state[dimension],
        ]),
      )

      changesRef.current = newChanges

      setChanges((prevChanges) => {
        if (!deepEqual(changesRef.current, prevChanges)) {
          return changesRef.current
        }
        return prevChanges
      })
    },
    [changes],
  )

  useEffect(() => {
    let cancelled = false

    setUpdating(true)

    computeStateChangesApiDebounced({
      subscriptionId,
      changes,
    }).then(
      (stateChanges) => {
        if (cancelled) return

        if (isFailure(stateChanges)) {
          switch (stateChanges.problem.type) {
            case 'temporary-api-failure':
              crash({ type: 'temporary-problem' })
              return
          }
        }
        setBefore(stateChanges.output.before)
        setAfter(stateChanges.output.after)
        overrideChangesByStateAfter(stateChanges.output.after)
        setNonDimensionalDataAfter(stateChanges.output.nonDimensionnalDataAfter)
        setInvalidStateMessages(stateChanges.output.stateChangeNotifications)
        setUpdating(false)
      },
      (err) => {
        if (cancelled) return

        crash({ type: 'unexpected-error', err: ensureError(err) })
      },
    )
    return () => {
      cancelled = true
    }
  }, [changes, crash, subscriptionId, overrideChangesByStateAfter])

  const updateChanges = useCallback(
    (newChanges: SubscriptionDimensionnedState) => {
      setChanges((changes) =>
        Object.fromEntries(
          Object.entries({ ...changes, ...newChanges }).filter(
            ([key, value]) => (before || {})[key as keyof SubscriptionDimensionnedState] !== value,
          ),
        ),
      )
    },
    [before],
  )

  return {
    changes,
    setChanges: updateChanges,
    before,
    after,
    nonDimensionalDataAfter,
    stateChangeNotifications,
    reset,
    commit,
    commiting,
    updating,
  }
}
