export type Service<INPUT extends unknown[], OUTPUT> = (...input: INPUT) => Promise<OUTPUT>

type PromiseRejectionCallback = (err: unknown) => void

type PendingCall = {
  timeoutId: number
  rejectionCallback: PromiseRejectionCallback
}

export class CallDebouncedError extends Error {
  constructor() {
    super('Call debounced')
  }
}

export function debounceAsync<INPUT extends unknown[], OUTPUT>(
  service: Service<INPUT, OUTPUT>,
  delay: number,
): Service<INPUT, OUTPUT> {
  let lastPendingCall: PendingCall | null = null

  return (...input: INPUT) => {
    if (lastPendingCall !== null) {
      clearTimeout(lastPendingCall.timeoutId)
      lastPendingCall.rejectionCallback(new CallDebouncedError())
    }
    return new Promise<OUTPUT>((resolve, reject) => {
      const currentPendingCall: PendingCall = {
        timeoutId: window.setTimeout(() => {
          service(...input).then(
            (result) => {
              if (currentPendingCall === lastPendingCall) {
                resolve(result)
              }
            },
            (err: unknown) => {
              if (currentPendingCall === lastPendingCall) {
                reject(err)
              }
            },
          )
        }, delay),
        rejectionCallback: reject,
      }
      lastPendingCall = currentPendingCall
    })
  }
}
